<script lang="jsx">
import ProductCardLabelsText from './ProductCardLabelsText.vue'
import { checkSlicingDomList } from '../../js/utils.js'

const textDataTag = ({ text, className = '', style = {}, icon = '', useItalic = false, suiIcon = '' }) => {
  return {
    key: 'text',
    textData: {
      text,
      icon,
      // 是否固定宽度，文字超出省略点点点
      ellipsis: false,
      className,
      style,
      useItalic,
      suiIcon

    }
  }
}

export default {
  name: 'ProductCardServiceTag',
  components: { ProductCardLabelsText },
  inject: ['labelsFromKey', 'config', 'item', 'language', 'setAttrForAnalysis'],
  props: {},
  data() {
    return {
      // 是否已初始化，先隐藏
      initialized: this.config.disableSlicingTagList,
      tagList: [],
      intersectionObserverExamples: null
    }
  },
  created() {
    this.initTagList()

    // 为了首屏执行让行 待展示时再执行
    const isClient = typeof window !== 'undefined'
    if (isClient && this.tagList?.length > 1 && !this.config.disableSlicingTagList) {
      this.intersectionObserverExamples = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio > 0) {
          this.initTagShow()

          this.intersectionObserverExamples.disconnect()
          this.intersectionObserverExamples = null
        }
      }) 
    }
  },
  async mounted() {
    if (this.config.disableSlicingTagList) return

    if (this.tagList?.length < 2) {
      this.initTagShow()
    } else {
      // 为了首屏执行让行 待展示时再执行
      this.intersectionObserverExamples.observe(this.$refs.tagList)
    }

  },
  beforeDestroy() {
    // 在组件销毁时停止观察
    if (this.intersectionObserverExamples) {
      this.intersectionObserverExamples.disconnect()
      this.intersectionObserverExamples = null
    }
  },
  methods: {
    async initTagShow() {
      await this.$nextTick()

      requestAnimationFrame(() => {
        // 组件需要先渲染判断所有标签，一行不够放则不展示
        if (this.tagList.length > 1) {
          this.checkSlicingTagList()
        }
        this.initialized = true
      })
    },
    initTagList() {
      this.tagList = [...this.initTextTag()]
    },
    initTextTag() {
      const { pretreatInfo, bffGoodsInfo } = this.item ?? {}

      const {
        showLocalSeller,
        showQuickShip,
        showBadge,
        hidePromotionLabel
      } = this.config ?? {}
      const labelsFromKey = this.labelsFromKey
      const { serviceLabels = [] } = pretreatInfo?.[labelsFromKey] ?? {}
      const textTag = []
      const anaText = []
      if (!serviceLabels?.length) return textTag
      // 服务类标签
      serviceLabels.forEach(({
        localMall,
        quickship,
        promotionLabel,
        universalLabel
      }) => {
        // 本地卖家
        if (showLocalSeller && localMall?.labelLang) {
          textTag.push(textDataTag({ text: localMall.labelLang, className: 'QuickShip' }))
          anaText.push(localMall.labelLangEn)
        }
        // 快速发货
        if (showQuickShip && quickship?.labelLang) {
          const { newStyle, italicText, suiIcon, appTraceInfo } = quickship || {}
          textTag.push(textDataTag({ 
            text: quickship.labelLang, 
            className: newStyle ? 'QuickShipNew' : 'QuickShip', 
            useItalic: italicText,
            suiIcon
          }))
          anaText.push(appTraceInfo || quickship.labelLangEn)
        }
        // 活动
        if (!hidePromotionLabel && promotionLabel) {
          textTag.push(textDataTag({ text: promotionLabel, className: 'Activity' }))
          anaText.push(promotionLabel)
        }
        // tsp角标 通用角标
        if (showBadge && universalLabel?.length) {
          universalLabel.forEach(({ appTraceInfo, fontColor, backgroundColor, labelId, icon, labelLang, labelLangEn }) => {
            const style = {
              color: fontColor ?? '',
              backgroundColor: backgroundColor ?? ''
            }
            textTag.push(textDataTag({ text: labelLang, className: labelLangEn?.replace(/\s/g, ''), style, icon }))
            anaText.push(appTraceInfo || `${labelId}_${labelLangEn}`)
          })
        }
        // 闪购
        // if (showNewFlashLabel && newFlashPromotion?.flashTypeText) {
        //   textTag.push(
        //     textDataTag({ text: newFlashPromotion?.flashTypeText, className: 'Activity' })
        //   )
        // }
        // 减价
        // if (showPriceCut && priceCut) {
        //   textTag.push(
        //     textDataTag({ text: this.language.SHEIN_KEY_PWA_18817, className: 'Activity' })
        //   )
        // }
      })
      if (textTag.length > 0) {
        this.setAttrForAnalysis((el) => {
          const anaPrefix = bffGoodsInfo ? '' : 'show_service_label_'

          el.setAttribute('data-service-label', `${anaPrefix}${anaText.join('_')}`.replace(/\s+/g, '_'))
        })
      }
      return textTag
    },
    /**
     * 计算宽度是否能在一行展示，不够则截断
     */
    checkSlicingTagList() {
      const { width } = this.$el?.getBoundingClientRect?.() || {}
      if (typeof width == 'undefined') return
      const tagListDom = this.$el.querySelectorAll('.product-card__service-tag-item')
      const { showIndexList, remainingWidth } = checkSlicingDomList(width, tagListDom)

      if (showIndexList.length) {
        // 如果还有超过40px剩余宽度，则再将一个插入并将不够的显示省略号样式。。。
        let lastEllipsis = false
        if (remainingWidth >= 40 && showIndexList.length !== this.tagList.length) {
          const lastIndex = this.tagList.findIndex((item, index) => {
            return !showIndexList.includes(index)
          })
          if (lastIndex !== -1) {
            showIndexList.push(lastIndex)
            lastEllipsis = true
          }
        }
        let newTagList = this.tagList.filter((item, index) => showIndexList.includes(index)).map((item, index) => {
          if (!item.textData) return item

          if (lastEllipsis && index === showIndexList.length - 1) {
            item.textData.style.width = `${remainingWidth}px`
            item.textData.ellipsis = true
          } else {
            item.textData.style.width = ''
            item.textData.ellipsis = false
          }

          return item
        })

        this.tagList = newTagList
      }
    }
  },
  render() {
    const tagItemUI = () => this.tagList.map((item) => (
      <li class="product-card__service-tag-item">
        {
          item.key == 'text'
            ? <ProductCardLabelsText text-Data={item.textData} />
            : null
        }
      </li>
    ))

    return this.tagList.length > 0 || this.$slots.default ? (
      <ul
        ref="tagList"
        class={[
          'product-card__service-tag-list',
          { 'transparent': !this.initialized },
          { 'wrap': this.config.disableSlicingTagList }
        ]}
      >
        { this.$slots.default }
        {tagItemUI()}
      </ul>
    ) : ''
  }
}
</script>

<style lang="less">
.product-card__service-tag-list {
  margin-top: .106rem;

  .flexbox();
  .align-center();

  flex-wrap: nowrap;
  overflow: hidden;
  
  &.transparent {
    opacity: 0;
  }
  &.wrap {
    flex-wrap: wrap;
    .product-card__service-tag-item  {
      margin-bottom: 0.16rem;
    }
  }
}

.product-card__service-tag-item {
  flex: 0 0 auto;
  margin-right: .106rem;
  max-width: 100%;
}
</style>
